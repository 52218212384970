import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import "../style.scss";

type DataProps = {
  allContentfulHomePage: {
    edges: [
      {
        node: {
          mainImg: {
            file: {
              url: string;
            };
            gatsbyImageData: IGatsbyImageData;
          };
          email: string;
          resumePdf: {
            url: string;
          };
          twitter: string;
          linkedin: string;
          header: string;
          description: {
            description: string;
          };
        };
      }
    ];
  };
};

const IndexPage = () => {
  const query: DataProps = useStaticQuery(graphql`
    query {
      allContentfulHomePage {
        edges {
          node {
            mainImg {
              file {
                url
              }
              gatsbyImageData(
                width: 750
                layout: CONSTRAINED
                resizingBehavior: SCALE
                quality: 100
              )
            }
            email
            resumePdf {
              url
            }
            twitter
            linkedin
            header
            description {
              description
            }
          }
        }
      }
    }
  `);
  const node = query.allContentfulHomePage.edges[0].node;
  return (
    <main className="about-page" style={{ opacity: 1 }}>
      <h1>{node.header}</h1>
      <section className="about-section">
        <GatsbyImage image={node.mainImg.gatsbyImageData} alt="biography art" />
        <div className="biography-wrapper">
          <div className="biography">{node.description.description}</div>
          <div className="links">
            <span className="contact-links">
              <a href={`mailto: ${node.email}`}>{node.email}</a>
              <a href={node.linkedin}>
                <i className="fa-brands fa-linkedin" />
              </a>
              <a href={node.twitter}>
                <i className="fa-brands fa-twitter" />
              </a>
            </span>
            <span className="resume">
              <a href={node.resumePdf.url}>resume: link</a>
            </span>
          </div>
        </div>
      </section>
    </main>
  );
};

export default IndexPage;
